(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
/*!
  * domready (c) Dustin Diaz 2014 - License MIT
  */
!function (name, definition) {

  if (typeof module != 'undefined') module.exports = definition()
  else if (typeof define == 'function' && typeof define.amd == 'object') define(definition)
  else this[name] = definition()

}('domready', function () {

  var fns = [], listener
    , doc = document
    , hack = doc.documentElement.doScroll
    , domContentLoaded = 'DOMContentLoaded'
    , loaded = (hack ? /^loaded|^c/ : /^loaded|^i|^c/).test(doc.readyState)


  if (!loaded)
  doc.addEventListener(domContentLoaded, listener = function () {
    doc.removeEventListener(domContentLoaded, listener)
    loaded = 1
    while (listener = fns.shift()) listener()
  })

  return function (fn) {
    loaded ? setTimeout(fn, 0) : fns.push(fn)
  }

});

},{}],2:[function(require,module,exports){
/* 
* Based on https://stackoverflow.com/a/23838252/448426
* Modified to use both localStorage and cookies redundantly instead as fallback and to store cookies on root domain
*/

module.exports = (function BrowserStorage() {
	/**
	 * Whether the current browser supports local storage as a way of storing data
	 * @var {Boolean}
	 */
	var _hasLocalStorageSupport = (function () {
		try {
			return 'localStorage' in window && window['localStorage'] !== null;
		} catch (e) {
			return false;
		}
	})();

	/**
	 * @param {String} name The name of the property to read from this document's cookies
	 * @return {?String} The specified cookie property's value (or null if it has not been set)
	 */
	var _readCookie = function (name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}

		return null;
	};

	/**
	 * @param {String} name The name of the property to set by writing to a cookie
	 * @param {String} value The value to use when setting the specified property
	 * @param {Number} [days] The number of days until the storage of this item expires
	 */
	var _writeCookie = function (name, value, days) {
		var expiration = (function () {
			if (days) {
				var date = new Date();
				date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
				return "; expires=" + date.toUTCString();
			}
			else {
				return "";
			}
		})();

		var domain = (function () {
			var i = 0, domain = document.domain, p = domain.split('.'), s = '_gd' + (new Date()).getTime();
			while (i < (p.length - 1) && document.cookie.indexOf(s + '=' + s) == -1) {
				domain = p.slice(-1 - (++i)).join('.');
				document.cookie = s + "=" + s + ";domain=" + domain + ";";
			}
			document.cookie = s + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" + domain + ";";
			return domain;
		})();
		document.cookie = name + "=" + value + expiration + "; path=/;domain=" + domain;
	};

	return {
		/**
		 * @param {String} name The name of the property to set
		 * @param {String} value The value to use when setting the specified property
		 * @param {Number} [days] The number of days until the cookie of this item expires 
		 */
		set: function (name, value, days) {
			if (_hasLocalStorageSupport) {
				localStorage.setItem(name, value);
			}
			_writeCookie(name, value, days);
		},

		/**
		 * @param {String} name The name of the value to retrieve
		 * @return {?String} The value of the 
		 */
		get: function (name) {
			if (_hasLocalStorageSupport && localStorage.getItem(name)) {
				return localStorage.getItem(name);
			}
			return _readCookie(name);
		},

		/**
		 * @param {String} name The name of the value to delete/remove from storage
		 */
		remove: function (name) {
			if (_hasLocalStorageSupport) {
				localStorage.removeItem(name)
			}
			this.set(name, "", -1);
		}
	};
});

},{}],3:[function(require,module,exports){
(function () {
	var domready = require("domready");
	var cs = document.currentScript || (function () {
		var scripts = document.querySelectorAll('[data-name="oaconversion"]');
		if (scripts[0]) {
			return scripts[0];
		}
		scripts = document.querySelectorAll('[data-advertiser]');
		return scripts[0];
	})();

	domready(function () {
		var BrowserStorage = require('./browserstorage')();

		if (cs) {
			var currentScript = cs;
			var advertiser = cs.getAttribute('data-advertiser');
			var advertiser_domain = cs.getAttribute('data-advertiser-domain');
			var offer_hash = cs.getAttribute('data-offer-hash');
			var offer_id = cs.getAttribute('data-offer-id');
			var unique_conversion_id = cs.getAttribute('data-unique-conversion-id');
			var ordervalue = cs.getAttribute('data-ordervalue');
			var event_id = cs.getAttribute('data-event-id');
			var data_click_id = cs.getAttribute('data-click-id');


			if (advertiser_domain == '' || advertiser_domain === null) {
				alert('OA Conversion: missing advertiser domain (GTM users enable document.write)');
			} else if (offer_id == '' || offer_id === null) {
				alert('OA Conversion: missing offer id');
			} else if (offer_hash == '' || offer_hash === null) {
				alert('OA Conversion: missing offer hash');
			}

			var click_id = BrowserStorage.get('oa-click-id');

			if (!click_id || click_id == '') {
				// check if click_id is in current url
				var param = cs.getAttribute('data-param') || cs.getAttribute('data-params') || 'oa_id,oa_clickid';
				var params = param.split(',');
				for (var i = 0; i < params.length; i++) {
					var x = params[i].replace(/^\s+|\s+$/gm, '');
					if (x.length) {
						click_id = decodeURIComponent((new RegExp('[?|&]' + x + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;

						if (click_id) {
							break;
						}
					}
				}
			}

			if (!click_id || click_id == '' && data_click_id && data_click_id != '') {
				click_id = data_click_id;
			}

			// request script-callback; with or without oa-click-id
			var data = {
				advertiser: advertiser,
				unique_conversion_id: unique_conversion_id,
				ordervalue: ordervalue,
				click_id: click_id
			}

			var form_data = new FormData();
			for (var key in data) {
				form_data.append(key, data[key]);
			}
			var url = window.location.protocol == 'https:' ? 'https' : 'http';
			url += '://' + advertiser_domain;
			url += '/m/' + offer_id + '/' + offer_hash + '/?unique_conversion_id=' + encodeURIComponent(unique_conversion_id);

			if (event_id && event_id != '') {
				url += '&event=' + encodeURIComponent(event_id);
			}

			var xhr = new XMLHttpRequest();
			if ("withCredentials" in xhr) {
				xhr.open('POST', url, true);
				xhr.withCredentials = true;
				xhr.onerror = function (e) {
					// error; call image pixel as fallback
					currentScript.onerror();
				}
				xhr.onload = function (e) {
					if (this.readyState == 4 && this.status == 200) {
						var response = JSON.parse(this.responseText);
						if (response.alt_image_pixel) {
							// request image pixel alternate (to fetch cookies on possible other domains) 
							var altImage = document.createElement('img');
							altImage.src = response.alt_image_pixel;
							altImage.style.width = '1px';
							altImage.style.height = '1px';
							altImage.style.border = '0px';

							document.body.appendChild(altImage);
						}
					}
				}
				xhr.send(form_data);

			} else {
				// NO XHR2; image pixel!
				currentScript.onerror();
			}
		} else {
			alert('OA Conversion pixel error!');
		}
	});
}).call(this);

},{"./browserstorage":2,"domready":1}]},{},[3]);
